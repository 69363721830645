/* Hero.css */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hero-section h1 {
  font-size: 4rem;
  color: white;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }
}
